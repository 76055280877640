import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const TermsIndex = () => (
    <Layout>
        <SEO description="When dealing with Ken Judge & Associates, please ensure you have read and fully understand the Terms and Conditions."
             title="Terms & Conditions"/>

        <PageTitle summary="When dealing with Ken Judge & Associates, please ensure you have read and fully understand the Terms and Conditions."
                   title="Terms & Conditions"/>

        <div className={"body-wrap"}>
                <p>Ken Judge & Associates Terms and Conditions ("Agreement")</p>
                <p>Please read these Terms and Conditions ("Agreement", "Terms and Conditions") carefully before using https://kenjudgeltd.co.uk ("the Site") operated by Ken Judge & Associates Ltd ("us", "we", or "our"). This Agreement sets forth the legally binding terms and conditions for your use of the Site at https://kenjudgeltd.co.uk.</p>
                <p>By accessing or using the Site in any manner, including, but not limited to, visiting or browsing the Site or contributing content or other materials to the Site, you agree to be bound by these Terms and Conditions. Capitalised terms are defined in this Agreement.</p>
                <h2>Intellectual Property</h2>
                <p>The Site and its original content, features and functionality are owned by Ken Judge & Associates Ltd and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
                <h2>Termination</h2>
                <p>We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                <h2>Links To Other Sites</h2>
                <p>Our Site may contain links to third-party sites that are not owned or controlled by Ken Judge & Associates Ltd.</p>
                <p>Ken Judge & Associates Ltd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services. We strongly advise you to read the terms and conditions and privacy policy of any third-party site that you visit.</p>
                <h2>Governing Law</h2>
                <p>This Agreement (and any further rules, polices, or guidelines incorporated by reference) shall be governed and construed in accordance with the laws of the United Kingdom, without giving effect to any principles of conflicts of law.</p>
                <h2>Changes To This Agreement</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions by posting the updated terms on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms and Conditions.</p>
                <p>Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use of the Site immediately.</p>
                <h2>Contact Us</h2>
                <p>If you have any questions about this Agreement, please <Link to={'/contact'}>contact us</Link>.</p>
        </div>
    </Layout>
)

export default TermsIndex
